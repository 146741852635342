import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { locales } from '~src/_i18n/i18n';
import { messages as msg } from '~src/_i18n/messages';
import useLocale from '~src/context/Hooks/useLocale';
const defaultLangContext = {
    activeLocale: null,
    setLocale: ()=>null,
    locales: null
};
export const LocaleContext = React.createContext(defaultLangContext);
export default function LocaleWrapper({ pageLocale, messages, children }) {
    const [locale, setLocale] = useLocale(pageLocale);
    const ssrMessages = messages ? messages : msg[locale];
    return React.createElement(LocaleContext.Provider, {
        value: {
            activeLocale: locale,
            setLocale,
            locales
        }
    }, React.createElement(IntlProvider, {
        locale: locale,
        messages: ssrMessages
    }, children));
}
