import * as React from 'react';
import { themes } from '../../_styles/theme';
import useTheme from '../Hooks/useTheme';
const defaultThemeContext = {
    theme: 'dark',
    setTheme: ()=>null,
    themes: themes
};
export const ThemeContext = React.createContext(defaultThemeContext);
const ThemeWrapper = ({ children })=>{
    const [theme, setTheme] = useTheme();
    return React.createElement(ThemeContext.Provider, {
        value: {
            theme,
            setTheme,
            themes
        }
    }, React.createElement(React.Fragment, null, children));
};
export default ThemeWrapper;
