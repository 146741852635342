import * as React from 'react';
import LocaleWrapper from './Context/LocaleContext';
import ThemeWrapper from './Context/ThemeContext';
import '~src/_styles/styles.scss';
export const Layout = ({ pageLocale, messages, children })=>{
    return React.createElement(React.Fragment, null, React.createElement(LocaleWrapper, {
        pageLocale: pageLocale,
        messages: messages
    }, React.createElement(ThemeWrapper, null, children)));
};
export default Layout;
