import tags from './tags/en';
export default {
    meta: {
        title: 'Alzahra Studio',
        desc: 'Where dreams can be lived'
    },
    notFound: {
        title: 'Page not found',
        goBack: 'Go to the front page →'
    },
    general: {
        allProjects: 'All Projects',
        allNews: 'All News',
        vfxReel: 'VFX Reel',
        animationReel: 'Animation Reel'
    },
    hero: {
        animation: 'Animation',
        animationText: `Alzahra Studio offers the end to end animation pipeline from the screenwriting to concept, to final render. We  produce world class 3d animations for TV and films.`,
        vfx: 'VFX',
        vfxText: `Alzahra Studio provides end-to-end production services such as concept art, art direction, matte painting and composition to
	assure filmmakers that their vision is realized on screen.`,
        originals: 'Alzahra Originals',
        originalsText: `Alzahra Studio is proud to annouce several new original intellectual properties are being produced.
		Stay tuned to learn more about work in progress projects!`
    },
    projects: {
        director: 'Director',
        release: 'Release date',
        films: 'Films',
        tv: 'TV',
        shorts: 'Shorts',
        commercials: 'Commercial',
        inProduction: 'In Production'
    },
    navigation: {
        home: 'Home',
        about: 'About',
        projects: 'Projects',
        news: 'News',
        careers: 'Careers',
        colorlab: 'Color',
        originals: 'Originals',
        contact: 'Contact',
        subscribe: 'Subscribe'
    },
    footer: {
        copyright: ' © ',
        youtube: 'YouTube',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
        home: 'Home',
        about: 'About'
    },
    subscribe: {
        title: 'Subscribe to Alzahra Studio',
        desc: '	Stay up to date! Get all the latest greatest posts delivered straight to your inbox',
        button: 'Subscribe',
        placeholder: 'youremail@example.com',
        error: 'Please enter a valid email address!',
        success: 'Great!',
        confirm: 'Check your inbox and click the link to confirm your subscription.',
        subscribed: 'You are already subscribed!',
        notification: {
            success: "You've successfully subscribed !",
            failure: 'Could not sign up! Invalid sign up link.'
        }
    },
    posts: {
        timeToRead: 'Min Read',
        seperator: ',',
        moreByAuthor: 'Read more by',
        moreByTag: 'Read more in',
        seeAll: 'See all {num, plural, one {# post} other {# posts}} →',
        collection: 'A collection of {num, plural, one {# post} other {# posts}} '
    },
    tags: tags
};
